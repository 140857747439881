const data = [
  {
    title: 'company',
    link: '/company',
  },
  {
    title: 'business',
    link: '/business',
  },
  {
    title: 'society',
    link: '/society',
  },
  {
    title: 'people',
    link: '/people',
  },
];

export default data;
