const data = [
  {
    key: 'history-01',
    date: '2022. 1.',
  },
  {
    key: 'history-02',
    date: '2016. 6.',
  },
  {
    key: 'history-03',
    date: '2015. 12.',
  },
  {
    key: 'history-04',
    date: '2014. 8.',
  },
  {
    key: 'history-05',
    date: '2014. 6.',
  },
  {
    key: 'history-06',
    date: '2013. 6.',
  },
  {
    key: 'history-07',
    date: '2012. 12.',
  },
  {
    key: 'history-08',
    date: '2011. 4.',
  },
  {
    key: 'history-09',
    date: '2009. 12.',
  },
  {
    key: 'history-10',
    date: '2007. 10.',
  },
];

export default data;
