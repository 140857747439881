const data = [
  {
    title: 'map-list-01',
    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3260.175852375405!2d129.0802662151779!3d35.20208846370755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3568936fe2033709%3A0x3ee8961272d34255!2z67aA7IKw6rSR7Jet7IucIOuPmeuemOq1rCDsiJjrr7zrj5kg7Lap66Cs64yA66GcIDIxMg!5e0!3m2!1sko!2skr!4v1637176133029!5m2!1sko!2skr',
  },
  {
    title: 'map-list-03',
    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.6587568040836!2d109.11009685086783!3d-7.049325271010587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6f95c95bfcb52f%3A0xc284296d28f186ab!2sPT.LEEA%20FOOTWEAR%20INDONESIA!5e0!3m2!1sko!2skr!4v1665967877368!5m2!1sko!2skr',
  },
  {
    title: 'map-list-04',
    url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7291.563416850671!2d106.60517761111309!3d11.39221216492415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174b222f74e3283%3A0xac17d82853615680!2sC%C3%B4ng%20Ty%20TNHH%20Beesco%20Vina!5e0!3m2!1sko!2skr!4v1637212051064!5m2!1sko!2skr',
  },
];

export default data;
